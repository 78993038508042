import { render, staticRenderFns } from "./accept-place-modal.vue?vue&type=template&id=45783c9e&scoped=true&"
import script from "./accept-place-modal.vue?vue&type=script&lang=js&"
export * from "./accept-place-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45783c9e",
  null
  
)

export default component.exports